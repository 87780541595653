.container-panah1{
    top: 10px;
    left: 100px;
    position:absolute;
}

.container-panah2{
    top: 60px;
    left: 120px;
    position:absolute;
}

.container-panah3{
    top: 100px;
    left: 160px;
    position:absolute;
}

.bg-panah {
    position: relative;
    text-align: center;
    width: 100px;
}

.top-center1{
    position:absolute;
    top: 4px;
    left: 35px;
    font-size: 10px;
}

.top-center{
    position:absolute;
    top: 2px;
    left: 35px;
    font-size: 11px;
}